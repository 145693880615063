import React, { useState, useEffect } from "react";
import { Container, Typography, Box, Button, Breadcrumbs, Link } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

const API_BASE_URL = "https://habka.hu";

const BlogPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/posts`);
        if (!response.ok) {
          throw new Error("Nem sikerült lekérni a postokat.");
        }
        const data = await response.json();
        setPosts(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <Typography variant="h6">Adatok betöltése...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Hiba történt: {error}
      </Typography>
    );
  }

  const currentIndex = posts.findIndex((post) => post._id === id);
  const blogPost = posts[currentIndex];

  if (!blogPost) {
    return <Typography variant="h5">A bejegyzés nem található!</Typography>;
  }

  const previousPost = posts[currentIndex - 1];
  const nextPost = posts[currentIndex + 1];

  return (
    <Container>
      <Box sx={{ mt: 4, pb: 5 }}>
        {/* Breadcrumb navigáció */}
        <Breadcrumbs sx={{ mb: 3 }}>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/")}
            sx={{ cursor: "pointer" }}
          >
            Főoldal
          </Link>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/blog")}
            sx={{ cursor: "pointer" }}
          >
            Blog
          </Link>
          <Typography color="text.primary">{blogPost.title}</Typography>
        </Breadcrumbs>

        <Box sx={{ mb: 3 }}>
          <img
            src={
              blogPost.image && blogPost.image.startsWith("/uploads/")
                ? `https://habka.hu${blogPost.image}` // Teljes URL-é alakítás
                : blogPost.image || "https://habka.hu/uploads/habka_nincs_kep.png" // Alapértelmezett kép
            }
            alt={blogPost.title}
            style={{
              width: "100%",
              maxHeight: "400px", // Maximális magasság beállítása
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </Box>

        <Typography variant="h3" gutterBottom>
          {blogPost.title}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
          {blogPost.content}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          {previousPost && (
            <Button
              variant="outlined"
              onClick={() => navigate(`/blog/${previousPost._id}`)}
              sx={{ textTransform: "none" }}
            >
              ← Előző: {previousPost.title}
            </Button>
          )}
          {nextPost && (
            <Button
              variant="outlined"
              onClick={() => navigate(`/blog/${nextPost._id}`)}
              sx={{ textTransform: "none" }}
            >
              Következő: {nextPost.title} →
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPage;
