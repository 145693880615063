import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import { useAuth } from "../context/AuthContext.tsx";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${backendURL}/api/admin/login`, {
        email,
        password,
      });

      const { token, user } = response.data;

      if (user.role === "admin") {
        login(token, user);
        navigate("/dashboard"); // Admin irányítása
      } else {
        alert("Nincs jogosultságod az admin felülethez!");
      }
    } catch (error) {
      console.error(
        "Hiba az admin bejelentkezés során:",
        error.response?.data?.message || error.message
      );
      alert("Hibás email vagy jelszó!");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4">Admin Bejelentkezés</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="Jelszó"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Button fullWidth type="submit" variant="contained" color="primary">
          Bejelentkezés
        </Button>
      </form>
    </Container>
  );
};

export default LoginPage;
