import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import BlogCard from "../components/PostCard.tsx";

const API_BASE_URL = "https://habka.hu/api";

const BlogList: React.FC = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/posts`);
        if (!response.ok) {
          throw new Error("Hiba a postok lekérdezésekor.");
        }
        const data = await response.json();

        // Dátum szerint csökkenő sorrendbe állítjuk a postokat
        const sortedPosts = data.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        setPosts(sortedPosts);
      } catch (err) {
        setError("Nem sikerült betölteni a postokat.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <Typography variant="h6" textAlign="center" sx={{ mt: 4 }}>
        Adatok betöltése...
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" textAlign="center" sx={{ mt: 4 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ mt: 4, textAlign: "center" }}>
        Tippek
      </Typography>
      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post._id}>
            <BlogCard
              blog={{
                id: post._id,
                title: post.title,
                summary: post.summary,
                image: post.image,
                date: post.date,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogList;
