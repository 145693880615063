import React from "react";

const Orders: React.FC = () => {
  return (
    <div>
      <h2>Rendelések</h2>
      <p>Itt jelennek meg a rendelések.</p>
    </div>
  );
};

export default Orders;
