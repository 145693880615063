import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext.tsx";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

const UserRegistrationOrLogin: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const endpoint = isLogin ? "/api/users/login" : "/api/users/register";
      const { data } = await axios.post(`${backendURL}${endpoint}`, {
        firstName: isLogin ? undefined : formData.firstName,
        lastName: isLogin ? undefined : formData.lastName,
        email: formData.email,
        password: formData.password,
      });
      setMessage(isLogin ? "Sikeres bejelentkezés!" : "Sikeres regisztráció!");
      login(data.token, data.user);
      navigate("/user-profile"); // Felhasználói profilra irányítás
    } catch (error) {
      setMessage(
        error.response?.data?.message || "Hiba történt. Próbáld újra később!"
      );
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          mx: "auto",
          mt: 5,
          p: 3,
          boxShadow: 2,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {isLogin ? "Bejelentkezés" : "Regisztráció"}
        </Typography>
        {!isLogin && (
          <>
            <TextField
              label="Vezetéknév"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Keresztnév"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </>
        )}
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Jelszó"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2 }}
        >
          {isLogin ? "Bejelentkezés" : "Regisztráció"}
        </Button>
        <Button
          onClick={() => {
            setIsLogin(!isLogin);
            setMessage("");
          }}
          fullWidth
          sx={{ mt: 1 }}
        >
          {isLogin
            ? "Nincs fiókod? Regisztrálj!"
            : "Van már fiókod? Jelentkezz be!"}
        </Button>
        {message && (
          <Typography color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default UserRegistrationOrLogin;
