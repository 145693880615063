import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

interface Product {
  _id?: string;
  name: string;
  price: number;
  stock: number;
  description: string;
  longDescription: string;
  category: string;
  subcategory: string;
  ingredients: { name: string; amount: string }[];
  images: string[];
}

export default function ProductsPage() {
  const [products, setProducts] = useState<Product[]>([]);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [dragging, setDragging] = useState(false);

  // Fetch products from backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/products`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Save product (add or edit)
  const handleSaveProduct = async () => {
    try {
      const productData = { ...editingProduct };

      if (editingProduct?._id) {
        const response = await axios.put(
          `${backendURL}/api/products/${editingProduct._id}`,
          productData
        );
        setProducts((prev) =>
          prev.map((product) =>
            product._id === editingProduct._id ? response.data : product
          )
        );
      } else {
        const response = await axios.post(
          `${backendURL}/api/products`,
          productData
        );
        setProducts((prev) => [...prev, response.data]);
      }

      setEditingProduct(null);
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  // Handle drag-and-drop for image upload
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    if (editingProduct) {
      const files = Array.from(event.dataTransfer.files);
      const formData = new FormData();

      files.forEach((file) => formData.append("images", file));

      try {
        const response = await axios.post(
          `${backendURL}/api/uploads`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setEditingProduct((prev) => ({
          ...prev!,
          images: [...(prev?.images || []), ...response.data.urls],
        }));
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  // Delete image
  const handleDeleteImage = async (imageUrl: string) => {
    try {
      await axios.post(`${backendURL}/api/uploads/delete`, { imageUrl });
      setEditingProduct((prev) => ({
        ...prev!,
        images: prev?.images.filter((img) => img !== imageUrl) || [],
      }));
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // Delete product
  const handleDeleteProduct = async (_id: string) => {
    try {
      await axios.delete(`${backendURL}/api/products/${_id}`);
      setProducts((prev) => prev.filter((product) => product._id !== _id));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <Container>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">Termékek</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setEditingProduct({
              name: "",
              price: 0,
              stock: 0,
              description: "",
              longDescription: "",
              category: "",
              subcategory: "",
              ingredients: [],
              images: [],
            })
          }
        >
          Termék hozzáadása
        </Button>
      </Box>

      {/* Product Form */}
      {editingProduct && (
        <Box
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          sx={{
            mb: 4,
            p: 2,
            border: dragging ? "2px dashed #1976d2" : "2px solid transparent",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6">
            {editingProduct._id
              ? "Termék szerkesztése"
              : "Új termék hozzáadása"}
          </Typography>

          {/* Képek megjelenítése és törlés */}
          {/* Drag-and-Drop zóna és fájlfeltöltés */}
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            sx={{
              border: dragging ? "2px dashed #1976d2" : "1px solid #ccc",
              borderRadius: "8px",
              p: 2,
              mb: 2,
              textAlign: "center",
              backgroundColor: dragging ? "#f0f0f0" : "transparent",
            }}
          >
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Húzd ide a képeket vagy kattints a feltöltéshez
            </Typography>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={async (e) => {
                if (e.target.files && editingProduct) {
                  const files = Array.from(e.target.files);
                  const formData = new FormData();

                  files.forEach((file) => formData.append("images", file));

                  try {
                    const response = await axios.post(
                      `${backendURL}/api/uploads`,
                      formData,
                      {
                        headers: { "Content-Type": "multipart/form-data" },
                      }
                    );
                    setEditingProduct((prev) => ({
                      ...prev!,
                      images: [...(prev?.images || []), ...response.data.urls],
                    }));
                  } catch (error) {
                    console.error("Error uploading images:", error);
                  }
                }
              }}
              style={{ display: "none" }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button variant="contained" color="primary" component="span">
                Fájlok kiválasztása
              </Button>
            </label>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2 }}>
            {editingProduct.images.map((image, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                <img
                  src={`${backendURL}${image}`}
                  alt={`Kép ${index + 1}`}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 8,
                    objectFit: "cover",
                  }}
                />
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: -8,
                    right: -8,
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => handleDeleteImage(image)}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ))}
          </Box>

          {/* Form fields */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <TextField
              label="Név"
              value={editingProduct.name}
              onChange={(e) =>
                setEditingProduct({ ...editingProduct, name: e.target.value })
              }
            />
            <TextField
              label="Ár"
              type="number"
              value={editingProduct.price}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  price: Number(e.target.value),
                })
              }
            />
            <TextField
              label="Készlet"
              type="number"
              value={editingProduct.stock}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  stock: Number(e.target.value),
                })
              }
            />
            <TextField
              label="Leírás"
              value={editingProduct.description}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  description: e.target.value,
                })
              }
            />
            <TextField
              label="Hosszú leírás"
              value={editingProduct.longDescription}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  longDescription: e.target.value,
                })
              }
            />
            <TextField
              label="Kategória"
              value={editingProduct.category}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  category: e.target.value,
                })
              }
            />
            <TextField
              label="Alkategória"
              value={editingProduct.subcategory}
              onChange={(e) =>
                setEditingProduct({
                  ...editingProduct,
                  subcategory: e.target.value,
                })
              }
            />

            {/* Ingredients */}
            <Box>
              <Typography variant="h6">Összetevők</Typography>
              {editingProduct.ingredients.map((ingredient, index) => (
                <Box key={index} sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    label="Név"
                    value={ingredient.name}
                    onChange={(e) => {
                      const ingredients = [...editingProduct.ingredients];
                      ingredients[index].name = e.target.value;
                      setEditingProduct({ ...editingProduct, ingredients });
                    }}
                  />
                  <TextField
                    label="Mennyiség"
                    value={ingredient.amount}
                    onChange={(e) => {
                      const ingredients = [...editingProduct.ingredients];
                      ingredients[index].amount = e.target.value;
                      setEditingProduct({ ...editingProduct, ingredients });
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      const ingredients = [...editingProduct.ingredients];
                      ingredients.splice(index, 1);
                      setEditingProduct({ ...editingProduct, ingredients });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={() =>
                  setEditingProduct((prev) => ({
                    ...prev!,
                    ingredients: [
                      ...prev!.ingredients,
                      { name: "", amount: "" },
                    ],
                  }))
                }
              >
                Összetevő hozzáadása
              </Button>
            </Box>
          </Box>

          <Button variant="contained" onClick={handleSaveProduct}>
            Mentés
          </Button>
        </Box>
      )}

      {/* Products table */}
      <TableContainer sx={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Név</TableCell>
              <TableCell align="center">Ár (Ft)</TableCell>
              <TableCell align="center">Készlet</TableCell>
              <TableCell align="center">Műveletek</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product._id}>
                <TableCell align="center">{product.name}</TableCell>
                <TableCell align="center">
                  {product.price.toLocaleString()}
                </TableCell>
                <TableCell align="center">{product.stock}</TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => setEditingProduct(product)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteProduct(product._id!)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
