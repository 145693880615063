import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Divider, Grid } from "@mui/material";
import ProductCard from "../components/ProductCard.tsx";

export default function SuccessPage() {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const orderNumber = "ORD-12345678"; // Example order number

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("/data/products.json");
        const products = await response.json();
        setRecommendedProducts(products.slice(0, 5)); // Fetch the first 5 products
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box height={"40vh"} sx={{ alignContent: "center" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Köszönjük a vásárlást!
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          A fizetés sikeres volt
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Rendelésszám: <strong>{orderNumber}</strong>
        </Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Ajánlott termékek
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          {recommendedProducts.slice(0,4).map((product) => (
            <Grid item xs={12} sm={6} md={3} key={product.id}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
