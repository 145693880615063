import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CompanyLogoWhite from "../assets/habka_feher_logo.png";
import { Link } from "react-router-dom";

interface HeroContent {
  id: number;
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  link: string;
}

export default function Hero() {
  const [heroContent, setHeroContent] = useState<HeroContent[]>([]);

  useEffect(() => {
    const fetchHeroContent = async () => {
      try {
        const response = await fetch("/data/heroContent.json");
        const data = await response.json();
        setHeroContent(data);
      } catch (error) {
        console.error("Hiba a hero tartalom betöltésekor:", error);
      }
    };

    fetchHeroContent();
  }, []);

  const heroSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1800px",
        margin: "0 auto",
        mt: 4,
        position: "relative", // Relatív pozíció a logó abszolút elhelyezéséhez
      }}
    >
      {/* Fixen pozícionált logó */}
      <Box
        component="img"
        src={CompanyLogoWhite}
        alt="Habka logo"
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "100px",
          height: "50px",
          zIndex: 10,
          "@media (max-width: 768px)": {
            // Mobil esetén
            right: "40px",
          },
        }}
      />

      {/* Hero Slider */}
      {heroContent.length > 0 && (
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <Slider {...heroSliderSettings}>
            {heroContent.map((item) => (
              <Box
                key={item.id}
                sx={{
                  position: "relative",
                  height: "70vh",
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  overflow: "hidden",
                }}
              >
                {/* Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    zIndex: 1,
                  }}
                ></Box>

                {/* Content */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    zIndex: 2,
                    color: "white",
                    padding: 3,
                    maxWidth: "30%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    "@media (max-width: 768px)": {
                      // Mobil esetén
                      bottom: "50%",
                      left: "50%",
                      maxWidth: "100%",
                      transform: "translate(-50%, 50%)", // Középre igazítás
                      textAlign: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      "@media (max-width: 768px)": {
                        fontSize: "3rem", // Mobil esetén kisebb betűméret
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      "@media (max-width: 768px)": {
                        fontSize: "1rem", // Mobil esetén kisebb betűméret
                      },
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                  <Button
                    component={Link}
                    to={item.link}
                    variant="contained"
                    color="primary"
                    sx={{
                      "@media (max-width: 768px)": {
                        width: "100%", // Gomb kitöltése mobilon
                        maxWidth: "300px", // Maximális szélesség
                      },
                    }}
                  >
                    {item.buttonText}
                  </Button>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      )}
    </Box>
  );
}
