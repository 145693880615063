import React from "react";
import { Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "75vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        color="primary"
        fontWeight="bold"
        gutterBottom
        sx={{
          fontSize: {
            xs: "3rem",
            sm: "6rem",
          },
        }}
      >
        404
      </Typography>
      <Typography
        variant="h5"
        color="textSecondary"
        sx={{ marginBottom: "1rem" }}
      >
        Az oldal, amit keresel, nem található.
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ marginBottom: "2rem" }}
      >
        Lehet, hogy a cím elgépelés történt, vagy az oldal már nem létezik.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={goHome}
        sx={{
          padding: "10px 20px",
          textTransform: "none",
        }}
      >
        Vissza a főoldalra
      </Button>
    </Container>
  );
};

export default NotFoundPage;
