import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCart } from "../context/CartContext.tsx";

interface ProductCardProps {
  product: {
    _id: string; // Backend által küldött azonosító
    name: string;
    stock: number;
    images: string[]; // Képek URL-jei
    price: number;
  };
}

const backendURL = "https://habka.hu"; // Az alap URL, ahol a backend fut

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const handleCardClick = () => {
    navigate(`/product/${product._id}`); // Az id használata az URL-ben
  };

  return (
    <Card
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: 0,
        overflow: "hidden",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f9f9f9",
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        sx={{
          position: "relative",
          aspectRatio: "3 / 4",
          width: "100%",
          perspective: "1000px",
        }}
        onClick={handleCardClick}
      >
        {/* Kép flip */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            transform: hovered ? "rotateX(0deg)" : "rotateX(-180deg)",
            transformStyle: "preserve-3d",
            transition: "transform 0.8s ease",
          }}
        >
          {/* Első kép (front) */}
          <CardMedia
            component="img"
            image={
              product.images && product.images.length > 0
                ? `${backendURL}${product.images[0]}`
                : `${backendURL}/uploads/habka_nincs_kep.png`
            }
            alt={product.name}
            sx={{
              position: "absolute",
              backfaceVisibility: "hidden",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          {/* Második kép (back) */}
          <CardMedia
            component="img"
            image={
              product.images && product.images.length > 1
                ? `${backendURL}${product.images[1]}`
                : `${backendURL}/uploads/habka_nincs_kep.png`
            }
            alt={`${product.name} második kép`}
            sx={{
              position: "absolute",
              backfaceVisibility: "hidden",
              transform: "rotateX(180deg)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Badge a készlet alapján */}
        {(product.stock === 0 || product.stock < 5) && (
          <Box
            sx={{
              position: "absolute",
              top: 8,
              left: 8,
              backgroundColor:
                product.stock === 0 ? "error.main" : "warning.main",
              color: "white",
              borderRadius: 1,
              px: 1,
              py: 0.5,
              fontSize: "0.75rem",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {product.stock === 0 ? "Elfogyott" : "Alacsony készlet"}
          </Box>
        )}
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          alignItems: "flex-start",
          justifyContent: "center",
          p: 0,
        }}
      >
        <Typography
          variant="body1"
          fontWeight={400}
          color="gray"
          gutterBottom
          textAlign="left"
          pt={1}
        >
          {product.name}
        </Typography>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "30px",
            perspective: "1000px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backfaceVisibility: "hidden",
              transform: hovered ? "rotateX(180deg)" : "rotateX(0deg)",
              transition: "transform 0.8s ease",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={500}
              color="primary"
              sx={{
                textAlign: "left",
              }}
            >
              {product.price.toLocaleString()} Ft
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backfaceVisibility: "hidden",
              transform: hovered ? "rotateX(0deg)" : "rotateX(-180deg)",
              transition: "transform 0.8s ease",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                addToCart(product);
              }}
              disabled={product.stock === 0} // Gomb letiltása, ha a készlet 0
            >
              {product.stock === 0 ? "Elfogyott" : "Kosárba"}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
