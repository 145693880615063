import React from "react";
import { Box, Typography, Container, Divider } from "@mui/material";
import {
    CompanyName,
    CompanyEmail,
    CompanyPhone,
    CompanyAddress,
    CompanyTaxNumber
  } from "../data/companyInfo.tsx";

const TermsAndConditions: React.FC = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: 4,
        marginBottom: 4,
        padding: 2,
        backgroundColor: "#ffffff",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 4 }}
      >
        Általános Szerződési Feltételek
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />
      <Box sx={{ lineHeight: 1.8 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          1. Bevezetés
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Jelen dokumentum tartalmazza a habka.hu (továbbiakban: Szolgáltató)
          által üzemeltetett weboldal használatának feltételeit. Kérjük, figyelmesen
          olvassa el az alábbi feltételeket, mielőtt használja a weboldalt.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          2. Szolgáltató adatai
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Cégnév: { CompanyName } <br />
          Székhely: { CompanyAddress } <br />
          Adószám: { CompanyTaxNumber } <br />
          Kapcsolattartó e-mail címe: { CompanyEmail } <br />
          Ügyfélszolgálat: { CompanyPhone }
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          3. A szolgáltatás igénybevétele
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A weboldalon történő vásárlás regisztrációval vagy regisztráció nélkül
          is lehetséges. A vásárló felelősséggel tartozik a megadott adatok
          helyességéért.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          4. Fizetési feltételek
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A vásárlásokért történő fizetés online bankkártyás fizetéssel vagy
          utánvéttel történik. A pontos fizetési módokat a megrendelés során
          tüntetjük fel.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          5. Szállítási információk
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A megrendelések szállítása futárszolgálattal történik, a megrendelést
          követő 3-5 munkanapon belül. A szállítási díjak a kosárban
          részletezve vannak.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          6. Elállási jog
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A vásárló az átvételtől számított 14 napon belül jogosult a vásárlástól
          indoklás nélkül elállni. Az elállási szándékot e-mailben kell jelezni a
          Szolgáltató felé.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          7. Adatkezelési tájékoztató
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A vásárló által megadott adatokat a Szolgáltató kizárólag a
          rendelés teljesítése érdekében használja fel, harmadik fél számára nem
          adja ki. Az adatkezelési szabályzat részletesen itt olvasható:{" "}
          <a href="/privacy-policy" style={{ color: "#1976d2", textDecoration: "none" }}>
            Adatkezelési tájékoztató
          </a>.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          8. Záró rendelkezések
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A jelen ÁSZF-ben nem szabályozott kérdésekben a magyar jog az irányadó.
          A felek az esetleges vitás kérdéseket békés úton próbálják rendezni.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
