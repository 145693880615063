import React, { useState } from "react";
import Products from "../components/Products.tsx";
import Orders from "../components/Orders.tsx";
import CompanyDetails from "../components/CompanyDetails.tsx";
import { useAuth } from "../context/AuthContext.tsx";
import Posts from "../components/Posts.tsx";
import NewPost from "../components/NewPost.tsx";
import { Container, Box, Typography, Button, Tabs, Tab } from "@mui/material";

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    "products" | "orders" | "company" | "posts" | "newPost"
  >("products");
  const [editingPostId, setEditingPostId] = useState<string | null>(null);

  const { user, logout } = useAuth();

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: "products" | "orders" | "company" | "posts" | "newPost"
  ) => {
    setActiveTab(newValue);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    logout();
  };

  const handleEditPost = (postId: string) => {
    setEditingPostId(postId);
    setActiveTab("newPost");
  };

  const handlePostSaved = () => {
    setEditingPostId(null);
    setActiveTab("posts");
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "300" }}>
            {user ? `Üdv, ${user.lastName}` : "Habka admin"}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleLogout}
            sx={{
              padding: "10px",
              fontSize: "0.875rem",
              minHeight: "unset",
              lineHeight: 1.2,
            }}
          >
            Kijelentkezés
          </Button>
        </Box>
      </Container>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => handleTabChange(event, value)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Termékek" value="products" />
          <Tab label="Rendelések" value="orders" />
          <Tab label="Cégadatok" value="company" />
          <Tab label="Postok" value="posts" />
          {activeTab === "newPost" && <Tab label="Új Post" value="newPost" />}
        </Tabs>
      </Box>

      <Box>
        {activeTab === "products" && <Products />}
        {activeTab === "orders" && <Orders />}
        {activeTab === "company" && <CompanyDetails />}
        {activeTab === "posts" && <Posts onEditPost={handleEditPost} />}
        {activeTab === "newPost" && (
          <NewPost
            postId={editingPostId}
            onTabChange={handleTabChange}
            onPostSaved={handlePostSaved}
          />
        )}
      </Box>
    </Container>
  );
};

export default Dashboard;
