import React from "react";
import { Box, Typography, Container, Divider } from "@mui/material";
import {
    CompanyName,
    CompanyEmail,
    CompanyPhone,
    CompanyAddress,
    CompanyTaxNumber
  } from "../data/companyInfo.tsx";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: 4,
        marginBottom: 4,
        padding: 2,
        backgroundColor: "#ffffff",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", marginBottom: 4 }}
      >
        Adatkezelési Szabályzat
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />
      <Box sx={{ lineHeight: 1.8 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          1. Bevezetés
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A jelen Adatkezelési Szabályzat tartalmazza, hogy a habka.hu (továbbiakban:
          Szolgáltató) miként kezeli a weboldal használata során megadott személyes
          adatokat.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          2. Az adatkezelő adatai
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Cégnév: { CompanyName } <br />
          Székhely: { CompanyAddress } <br />
          Adószám: { CompanyTaxNumber } <br />
          Kapcsolattartó e-mail címe: { CompanyEmail } <br />
          Ügyfélszolgálat: { CompanyPhone }
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          3. Az adatkezelés célja
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Az adatkezelés célja a megrendelések teljesítése, ügyfélkapcsolat
          fenntartása, marketing tevékenység és jogszabályi kötelezettségek
          teljesítése.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          4. Az adatkezelés jogalapja
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Az adatkezelés az érintett hozzájárulásán, a szerződés teljesítéséhez
          szükséges adatkezelésen, valamint jogi kötelezettség teljesítésén alapul.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          5. Kezelt adatok köre
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          - Név <br />
          - E-mail cím <br />
          - Telefonszám <br />
          - Számlázási és szállítási cím <br />
          - Megrendelési adatok <br />
          - IP-cím
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          6. Az adatok tárolásának időtartama
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Az adatokat a jogszabályban előírt ideig vagy az adatkezelési cél
          megvalósulásáig tároljuk.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          7. Az érintettek jogai
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Az érintett jogosult tájékoztatást kérni az adatkezelésről, kérheti
          az adatok helyesbítését, törlését, valamint tiltakozhat az adatkezelés
          ellen. Ezeket az igényeket e-mailben a{" "}
          <a href="mailto:info@habka.hu" style={{ color: "#1976d2", textDecoration: "none" }}>
            info@habka.hu
          </a>{" "}
          címre lehet benyújtani.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          8. Adatbiztonság
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          A Szolgáltató gondoskodik az adatok biztonságos tárolásáról, és minden
          szükséges intézkedést megtesz azok védelme érdekében.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          9. Kapcsolat
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Kérdés esetén kérjük, vegye fel velünk a kapcsolatot az alábbi
          e-mail címen:{" "}
          <a href={`mailto:${CompanyEmail}`} style={{ color: "#1976d2", textDecoration: "none" }}>
            { CompanyEmail }
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
