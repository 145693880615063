import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/HABKA_logo.png";
import { useCart } from "../context/CartContext.tsx";
import { useAuth } from "../context/AuthContext.tsx";

function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { getCartCount } = useCart();
  const { user } = useAuth(); // Felhasználó adatok AuthContext-ből

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      keepMounted
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/shop" style={{ color: "#111111", textDecoration: "none" }}>
          Webshop
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to="/about" style={{ color: "#111111", textDecoration: "none" }}>
          Rólunk
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link
          to="/contact"
          style={{ color: "#111111", textDecoration: "none" }}
        >
          Kapcsolat
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link
          to="/blog"
          style={{ color: "#111111", textDecoration: "none" }}
        >
          Tippek
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "secondary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 4 }}>
            <Link
              to="/shop"
              style={{ color: "#111111", textDecoration: "none" }}
            >
              Webshop
            </Link>
            <Link
              to="/about"
              style={{ color: "#111111", textDecoration: "none" }}
            >
              Rólunk
            </Link>
            <Link
              to="/contact"
              style={{ color: "#111111", textDecoration: "none" }}
            >
              Kapcsolat
            </Link>
            <Link
              to="/blog"
              style={{ color: "#111111", textDecoration: "none" }}
            >
              Tippek
            </Link>
          </Box>

          <Link
            to="/"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Box
              component="img"
              src={CompanyLogo}
              alt="HABKA Logo"
              sx={{
                height: "40px",
                objectFit: "contain",
              }}
            />
          </Link>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 600px)": {
                gap: 0,
              },
            }}
          >
            {user && (
              <Typography
                variant="body1"
                sx={{
                  color: "#111111",
                  textAlign: "right",
                  marginRight: "10px", // Távolság csökkentése
                  display: { xs: "none", md: "block" }, // Mobilon elrejtés
                }}
              >
                Szia, { user.lastName }!
              </Typography>
            )}
            <IconButton
              size="large"
              sx={{ color: "#111111" }}
              component={Link}
              to={user ? "/user-profile" : "/auth"} // Navigáció user állapot alapján
            >
              <AccountCircle />
            </IconButton>
            <IconButton
              component={Link}
              to="/cart"
              size="large"
              sx={{ color: "#111111" }}
            >
              <Badge badgeContent={getCartCount()} color="primary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>

          <IconButton
            size="large"
            edge="start"
            color="#111111"
            aria-label="menu"
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {renderMobileMenu}
      </AppBar>
      <Toolbar />
    </>
  );
}

export default Navbar;
