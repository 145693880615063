import React from 'react';
import { Typography, Button, Badge } from '@mui/material';
import { CompanyName } from './data/companyInfo.tsx';
import { Box } from '@mui/system';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

function StyleGuide() {
  return (
    <div>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        }}>
            <Box bgcolor='primary.main' sx={{ flex: 1, height: "10rem" }}>Elsődleges Kék</Box>
            <Box bgcolor='primary.light' sx={{ flex: 1 }}>Másodlagos Kék</Box>
            <Box bgcolor='primary.dark' sx={{ flex: 1 }}>Harmadlagos Kék</Box>
        </Box>
      <Typography variant='h1' component="h1" color='primary.main'>{ CompanyName }</Typography>
      <Typography variant='h2' component="h2" color='primary.light'>{ CompanyName }</Typography>
      <Typography variant='h3' component="h3" color='primary.dark'>{ CompanyName }</Typography>
      <Typography variant='h4' component="h4" color='secondary.main'>{ CompanyName }</Typography>
      <Typography variant='h5' component="h5" color='secondary.light'>{ CompanyName }</Typography>
      <Typography variant='h6' component="h6" color='secondary.dark'>{ CompanyName }</Typography>
      <Typography variant='body1' component="p">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo, magnam!</Typography>
      <Button variant='primaryButton'>Click here</Button>
      <Button variant='secondaryButton'>Click here</Button>
    <Badge badgeContent={2} color="primary">
        <ShoppingBagIcon color="action" />
    </Badge>

    </div>
  );
}

export default StyleGuide;
