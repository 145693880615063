import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Collapse,
  Checkbox,
  FormControlLabel,
  TextField,
  Pagination,
} from "@mui/material";
import ProductCard from "../components/ProductCard.tsx";

interface Product {
  _id: string;
  name: string;
  images: string[];
  description: string;
  price: number;
  category: string;
  subcategory: string;
}

const ITEMS_PER_PAGE = 12;

function ShopPage() {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState<string[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // API végpont használata
        const response = await fetch("https://habka.hu/api/products");
        const data = await response.json();
        setProducts(data);
        setFilteredProducts(data);
      } catch (error) {
        console.error("Hiba a termékek betöltésekor:", error);
      }
    };

    fetchProducts();
  }, []);

  const uniqueCategories = Array.from(
    new Set(products.map((product) => product.category))
  );

  const getSubcategoriesForCategory = (category: string) => {
    return Array.from(
      new Set(
        products
          .filter((product) => product.category === category)
          .map((product) => product.subcategory)
      )
    );
  };

  const handleCategoryClick = (category: string | null) => {
    setCurrentCategory(category);
    setSelectedSubcategories([]);
    setSearchTerm("");
    setCurrentPage(1); // Reset to first page
    setFilteredProducts(
      category
        ? products.filter((product) => product.category === category)
        : products
    );
  };

  const handleSubcategoryChange = (subcategory: string) => {
    const newSubcategories = selectedSubcategories.includes(subcategory)
      ? selectedSubcategories.filter((subcat) => subcat !== subcategory)
      : [...selectedSubcategories, subcategory];

    setSelectedSubcategories(newSubcategories);

    const filteredBySubcategories = newSubcategories.length
      ? products.filter(
          (product) =>
            product.category === currentCategory &&
            newSubcategories.includes(product.subcategory)
        )
      : products.filter((product) => product.category === currentCategory);

    setCurrentPage(1); // Reset to first page
    setFilteredProducts(filteredBySubcategories);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    const searchFiltered = products.filter(
      (product) =>
        (!currentCategory || product.category === currentCategory) &&
        product.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setCurrentPage(1); // Reset to first page
    setFilteredProducts(searchFiltered);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const displayedProducts = filteredProducts.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Kategóriák és Szűrők */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {/* Kategóriák */}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              cursor: "pointer",
              textDecoration: currentCategory === null ? "underline" : "none",
            }}
            onClick={() => handleCategoryClick(null)}
          >
            Összes /
          </Typography>
          {uniqueCategories.map((category) => (
            <Typography
              key={category}
              variant="subtitle1"
              sx={{
                cursor: "pointer",
                textDecoration:
                  currentCategory === category ? "underline" : "none",
              }}
              onClick={() => handleCategoryClick(category)}
            >
              {category} /
            </Typography>
          ))}
        </Box>

        {/* Szűrő és Kereső */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="text"
            size="small"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            sx={{
              textTransform: "none",
              color: "inherit",
              borderRadius: 0,
              fontWeight: "400",
              borderBottom: "1px solid",
              border: "none",
              "&:hover": {
                backgroundColor: "transparent",
                borderBottom: "1px solid",
                borderColor: "primary.main",
              },
            }}
          >
            Filter
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            sx={{
              textTransform: "none",
              color: "inherit",
              fontWeight: "400",
              borderBottom: "1px solid",
              borderRadius: 0,
              border: "none",
              "&:hover": {
                backgroundColor: "transparent",
                borderBottom: "1px solid",
                borderColor: "primary.main",
              },
            }}
          >
            Keresés
          </Button>
        </Box>
      </Box>

      {/* Szűrők lenyíló */}
      <Collapse in={isFilterOpen}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Alkategóriák
          </Typography>
          {currentCategory &&
            getSubcategoriesForCategory(currentCategory).map((subcategory) => (
              <FormControlLabel
                key={subcategory}
                control={
                  <Checkbox
                    checked={selectedSubcategories.includes(subcategory)}
                    onChange={() => handleSubcategoryChange(subcategory)}
                  />
                }
                label={subcategory}
              />
            ))}
        </Box>
      </Collapse>

      {/* Kereső lenyíló */}
      <Collapse in={isSearchOpen}>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Mit keresel?"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              disableUnderline: true, // Alapértelmezett underline eltávolítása
              sx: {
                borderBottom: "1px solid#d7d7d7", // Alapértelmezett állapot
                "&:hover": {
                  borderBottom: "1px solid #d7d7d7", // Hover állapot
                },
                "&.Mui-focused": {
                  borderBottom: "1px solid #d7d7d7", // Fókusz állapot
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#d7d7d7", // Label színe
                "&.Mui-focused": {
                  color: "#b2b2b2", // Fókuszban lévő label színe
                },
              },
            }}
          />
        </Box>
      </Collapse>

      {/* Termékek */}
      <Grid container spacing={2}>
        {displayedProducts.map((product) => (
          <Grid item key={product._id} xs={6} sm={6} md={3}>
            <ProductCard product={product} />
          </Grid>
        ))}
        {displayedProducts.length === 0 && (
          <Typography variant="body1" align="center" sx={{ mt: 4 }}>
            Nincs találat.
          </Typography>
        )}
      </Grid>

      {/* Pagináció */}
      <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(filteredProducts.length / ITEMS_PER_PAGE)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
}

export default ShopPage;
