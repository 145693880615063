import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar.tsx";
import StyleGuide from "./StyleGuide.tsx";
import Home from "./pages/Home.tsx";
import About from "./pages/About.tsx";
import Shop from "./pages/Shop.tsx";
import Contact from "./pages/Contact.tsx";
import Footer from "./components/Footer.tsx";
import ProductPage from "./pages/ProductPage.tsx";
import CheckoutPage from "./pages/CheckoutPage.tsx";
import CartPage from "./pages/CartPage.tsx";
import Posts from "./components/Posts.tsx";
import NewPost from "./components/NewPost.tsx";
import ThankYouPage from "./pages/ThankYouPage.tsx";
import LoginPage from "./pages/LoginPage.tsx";
import Dashboard from "./pages/Dashboard.tsx";
import ProtectedRoute from "./components/ProtectedRoute.tsx";
import TermsAndConditions from "./pages/TermsAndConditions.tsx";
import BlogList from "./pages/PostList.tsx";
import BlogPage from "./pages/PostPage.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.tsx";
import { AuthProvider } from "./context/AuthContext.tsx";
import { CartProvider } from "./context/CartContext.tsx";
import UserRegistrationOrLogin from "./pages/UserRegistrationOrLogin.tsx";
import NotFoundPage from "./pages/NotFoundPage.tsx";
import UserProfile from "./pages/UserProfile.tsx"; // Importáld a UserProfile oldalt

function App() {
  return (
    <Router>
      <AuthProvider>
        <CartProvider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Navbar />
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/style-guide" element={<StyleGuide />} />
                <Route path="/product/:productId" element={<ProductPage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/thank-you-page" element={<ThankYouPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:id" element={<BlogPage />} />
                <Route path="/auth" element={<UserRegistrationOrLogin />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/user-profile" element={<UserProfile />} />{" "}
                  <Route path="/posts" element={<Posts />} />
                  <Route path="/posts/new" element={<NewPost />} />
                  {/* UserProfile hozzáadva */}
                </Route>
              </Routes>
            </Box>
            <Footer />
          </Box>
        </CartProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
