import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import ProductSlider from "../components/ProductSlider.tsx";
import ContactImage from "../assets/kapcsolat.jpeg";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    acceptPrivacy: false,
  });

  const [error, setError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.message ||
      !formData.acceptPrivacy
    ) {
      setError(
        "Kérjük, töltse ki az összes mezőt és fogadja el az adatkezelési feltételeket!"
      );
      return;
    }

    try {
      const response = await fetch("https://habka.hu/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      if (response.ok) {
        alert("Az üzenetet elküldtük!");
        setFormData({
          name: "",
          email: "",
          message: "",
          acceptPrivacy: false,
        });
      } else {
        const data = await response.json();
        alert(data.error || "Hiba történt az üzenet elküldése során.");
      }
    } catch (error) {
      console.error("Hiba az üzenet küldésekor:", error);
      alert("Hiba történt az üzenet elküldése során.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
        mb: 6,
      }}
    >
      <Grid container spacing={4} sx={{ maxWidth: "1200px", width: "100%" }}>
        {/* Left Column - Image */}
        <Grid item xs={12} md={6}>
          <div
            style={{ position: "relative", width: "100%", paddingTop: "100%" }}
          >
            <img
              src={ContactImage}
              alt="Kapcsolat"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </div>
        </Grid>

        {/* Right Column - Form */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Kapcsolat
          </Typography>
          <Typography variant="body1" gutterBottom>
            Keressen bennünket üzenetben
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Név"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              label="E-mail"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              label="Üzenet"
              fullWidth
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptPrivacy"
                  checked={formData.acceptPrivacy}
                  onChange={handleChange}
                />
              }
              label={
                <span>
                  Elfogadom az{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    style={{ color: "#1976d2", textDecoration: "none" }}
                  >
                    Adatkezelési Szabályzatot
                  </a>
                </span>
              }
            />
            {error && <Typography color="error">{error}</Typography>}

            {/* A gomb most az elfogadási checkbox alatt van */}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Küldés
            </Button>
          </form>
        </Grid>
      </Grid>

      {/* ProductSlider */}
      <Box
        sx={{
          mt: 4,
          width: "100%",
          maxWidth: "1200px", // Maximális szélesség beállítása
          mx: "auto", // Középre igazítás
          px: 2, // Oldalsó padding a kisebb képernyők számára
        }}
      >
        <Typography variant="h4" gutterBottom>
          Népszerű termékeink
        </Typography>
        <ProductSlider />
      </Box>
    </Box>
  );
}
