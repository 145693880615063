import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useCart } from "../context/CartContext.tsx";
import { useNavigate } from "react-router-dom";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

export default function CartPage() {
  const { cartItems, removeFromCart, updateCartItemQuantity } = useCart();
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleRemoveItem = (_id: number) => {
    removeFromCart(_id);
  };

  const handleQuantityChange = (id: number, delta: number) => {
    updateCartItemQuantity(id, delta);
  };

  const handleApplyCoupon = () => {
    if (couponCode === "DISCOUNT10") {
      setDiscount(0.1);
      setErrorMessage("");
    } else if (couponCode === "DISCOUNT20") {
      setDiscount(0.2);
      setErrorMessage("");
    } else {
      setDiscount(0);
      setErrorMessage("Érvénytelen kuponkód!");
    }
  };

  const totalWithoutDiscount = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const totalWithDiscount = totalWithoutDiscount * (1 - discount);

  const handleCheckout = () => {
    navigate("/checkout");
  };

  const EmptyCart = () => (
    <Box
      sx={{
        textAlign: "center",
        mt: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <ShoppingCartOutlinedIcon sx={{ fontSize: 80, color: "gray" }} />
      <Typography variant="h5" sx={{ fontWeight: "400" }}>
        A kosarad üres
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Úgy tűnik, még nem adtál hozzá terméket.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/shop")}
      >
        Webshop megtekintése
      </Button>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Kosár
      </Typography>

      {cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <>
          <Box
            sx={{ mb: 2, display: "flex", justifyContent: "flex-start", gap: 2 }}
          >
            <TextField
              label="Kuponkód"
              variant="outlined"
              size="small"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <Button variant="outlined" onClick={handleApplyCoupon}>
              Alkalmaz
            </Button>
          </Box>
          {errorMessage && (
            <Typography variant="body2" color="error" gutterBottom>
              {errorMessage}
            </Typography>
          )}

          <List>
            {cartItems.map((item, index) => (
              <Box key={item.id || index} sx={{ mb: 3 }}>
                <ListItem disableGutters>
                  <Box
                    component="img"
                    src={
                      item.images && item.images.length > 0
                        ? `${backendURL}${decodeURIComponent(item.images[0])}`
                        : `${backendURL}/uploads/habka_nincs_kep.png`
                    }
                    alt={item.name}
                    sx={{ width: 100, height: 100, borderRadius: 1, mr: 2 }}
                  />

                  <ListItemText
                    primary={item.name}
                    secondary={`${item.price.toLocaleString()} Ft x ${
                      item.quantity
                    }`}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleQuantityChange(item._id, -1)}
                    >
                      -
                    </Button>
                    <Typography>{item.quantity}</Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleQuantityChange(item._id, 1)}
                      disabled={item.quantity >= item.stock}
                    >
                      +
                    </Button>
                  </Box>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveItem(item._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>

          <Box sx={{ mt: 4, textAlign: "right" }}>
            <Typography variant="h6" gutterBottom>
              Összesen kedvezmény nélkül:{" "}
              {totalWithoutDiscount.toLocaleString()} Ft
            </Typography>
            {discount > 0 && (
              <Typography variant="h6" gutterBottom>
                Kedvezmény: {Math.round(discount * 100)}%
              </Typography>
            )}
            <Typography variant="h5" gutterBottom>
              Fizetendő: {totalWithDiscount.toLocaleString()} Ft
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleCheckout}
            >
              Fizetés
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
