import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Breadcrumbs,
  Tabs,
  Tab,
} from "@mui/material";
import ProductCard from "../components/ProductCard.tsx";
import axios from "axios";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://habka.hu"; // Alapértelmezett backend URL

interface Product {
  _id: string;
  name: string;
  images?: string[];
  description: string;
  longDescription: string;
  price: number;
  category: string;
  ingredients?: { name: string; amount: string }[];
}

export default function ProductPage() {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!productId) {
      console.error("Hiányzó termékazonosító.");
      return;
    }

    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/products/${productId}`
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Hiba a termék betöltésekor:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (!product) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography variant="h4" align="center">
          Termék nem található.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Breadcrumb */}
      <Breadcrumbs sx={{ mb: 3 }} aria-label="breadcrumb">
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          Főoldal
        </Link>
        <Link to={`/shop`} style={{ textDecoration: "none", color: "inherit" }}>
          Shop
        </Link>
        <Typography color="text.primary">{product.name}</Typography>
      </Breadcrumbs>

      {/* Termék adatok */}
      <Grid container spacing={4}>
        {/* Bal oldali kép */}
        <Grid item xs={12} md={6}>
          {product.images && product.images.length > 0 ? (
            <Box
              component="img"
              src={`${backendURL}${product.images[0]}`} // Az első képhez hozzáadjuk a backend URL-t
              alt={product.name}
              sx={{
                width: "100%",
                borderRadius: 2,
                objectFit: "cover",
              }}
            />
          ) : (
            <Typography variant="body1" color="text.secondary">
              Kép nem érhető el.
            </Typography>
          )}
        </Grid>

        {/* Jobb oldali adatok */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {product.name}
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            {product.description}
          </Typography>
          <Typography variant="h5" color="primary" gutterBottom>
            {product.price.toLocaleString()} Ft
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Kosárba
          </Button>
        </Grid>
      </Grid>

      {/* Tabos rész */}
      <Box
        sx={{
          mt: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="termék adatok"
        >
          <Tab label="Összetevők" />
          <Tab label="Leírás" />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              Összetevők
            </Typography>
            {product.ingredients && product.ingredients.length > 0 ? (
              <List>
                {product.ingredients.map((ingredient, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    sx={{ justifyContent: "center" }}
                  >
                    <ListItemText
                      primary={ingredient.name}
                      secondary={ingredient.amount}
                      sx={{ textAlign: "center" }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="text.secondary">
                Nincsenek összetevők megadva.
              </Typography>
            )}
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="body1" color="text.secondary">
              {product.longDescription || "Nincs további leírás."}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Kapcsolódó termékek */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Kapcsolódó termékek
        </Typography>
        <Grid container spacing={2}>
          {relatedProducts.map((relatedProduct) => (
            <Grid item key={relatedProduct._id} xs={12} sm={6} md={3}>
              <ProductCard product={relatedProduct} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
