import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const API_BASE_URL = "https://habka.hu/api";

interface NewPostProps {
  postId?: string | null;
  onTabChange: (
    event: React.SyntheticEvent,
    newValue: "products" | "orders" | "company" | "posts" | "newPost"
  ) => void;
  onPostSaved: () => void;
}

const NewPost: React.FC<NewPostProps> = ({
  postId,
  onTabChange,
  onPostSaved,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    summary: "",
    content: "",
    images: [] as string[],
    date: new Date().toISOString(),
  });
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPost = async () => {
      if (!postId) return;
      try {
        const response = await fetch(`${API_BASE_URL}/posts/${postId}`);
        const data = await response.json();
        setFormData({
          title: data.title,
          summary: data.summary,
          content: data.content,
          images: data.images || [],
          date: data.date || new Date().toISOString(),
        });
      } catch (error) {
        console.error("Hiba a post betöltésekor:", error);
      }
    };

    fetchPost();
  }, [postId]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const files = Array.from(event.dataTransfer.files);
    const formData = new FormData();

    files.forEach((file) => formData.append("images", file));

    try {
      const response = await fetch(`${API_BASE_URL}/uploads`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...data.urls],
      }));
    } catch (error) {
      console.error("Hiba a képfeltöltés során:", error);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  const handleDeleteImage = async (imageUrl: string) => {
    try {
      await fetch(`${API_BASE_URL}/uploads/delete`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ imageUrl }),
      });
      setFormData((prev) => ({
        ...prev,
        images: prev.images.filter((img) => img !== imageUrl),
      }));
    } catch (error) {
      console.error("Hiba a kép törlésekor:", error);
    }
  };

  const handleSubmit = async () => {
    const updatedData: any = {
      title: formData.title,
      summary: formData.summary,
      content: formData.content,
      date: formData.date,
      images: formData.images,
    };

    try {
      const method = postId ? "PUT" : "POST";
      const url = postId
        ? `${API_BASE_URL}/posts/${postId}`
        : `${API_BASE_URL}/posts`;

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Hiba történt a post mentésekor.");
      }

      onPostSaved();
    } catch (err: any) {
      setError(err.message || "Nem sikerült menteni a postot.");
    }
  };

  return (
    <Container sx={{ pt: 4, pb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {postId ? "Post Szerkesztése" : "Új Post Hozzáadása"}
      </Typography>
      {error && (
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
      )}
      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cím"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              sx={{
                border: dragging ? "2px dashed #1976d2" : "1px solid #ccc",
                borderRadius: "8px",
                p: 2,
                mb: 2,
                textAlign: "center",
                backgroundColor: dragging ? "#f0f0f0" : "transparent",
              }}
            >
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Húzd ide a képeket vagy kattints a feltöltéshez
              </Typography>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={async (e) => {
                  if (e.target.files) {
                    const files = Array.from(e.target.files);
                    const formData = new FormData();

                    files.forEach((file) => formData.append("images", file));

                    try {
                      const response = await fetch(`${API_BASE_URL}/uploads`, {
                        method: "POST",
                        body: formData,
                      });
                      const data = await response.json();
                      setFormData((prev) => ({
                        ...prev,
                        images: [...prev.images, ...data.urls],
                      }));
                    } catch (error) {
                      console.error("Hiba a képfeltöltés során:", error);
                    }
                  }
                }}
                style={{ display: "none" }}
                id="file-input"
              />
              <label htmlFor="file-input">
                <Button variant="contained" color="primary" component="span">
                  Fájlok kiválasztása
                </Button>
              </label>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2 }}>
              {formData.images.map((image, index) => (
                <Box key={index} sx={{ position: "relative" }}>
                  <img
                    src={`${API_BASE_URL}${image}`}
                    alt={`Kép ${index + 1}`}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 8,
                      objectFit: "cover",
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      backgroundColor: "red",
                      color: "white",
                    }}
                    onClick={() => handleDeleteImage(image)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Összegzés"
              name="summary"
              value={formData.summary}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={8}
              label="Tartalom"
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              Mentés
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default NewPost;
