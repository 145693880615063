import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../context/AuthContext.tsx";
import { useNavigate } from "react-router-dom";
import UserProfileForm from "../components/UserProfileForm.tsx";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

const UserProfile: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"orders" | "addresses">("orders");
  const [orders, setOrders] = useState([]);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: "orders" | "addresses"
  ) => {
    setActiveTab(newValue);
  };

  const fetchProfileData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      const ordersResponse = await axios.get(`${backendURL}/api/orders`, {
        headers,
      });

      setOrders(ordersResponse.data.orders);
    } catch (error) {
      console.error("Hiba a profiladatok betöltésekor:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    logout();
    navigate("/");
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  if (loading) {
    return <Typography>Betöltés...</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Fejléc */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "300" }}>
          {user ? `Szia, ${user.lastName}` : "Profilom"}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={handleLogout}
        >
          Kijelentkezés
        </Button>
      </Box>

      {/* Tabok */}
      <Tabs
        value={activeTab}
        onChange={(event, value) => handleTabChange(event, value)}
        sx={{ margin: "20px" }}
      >
        <Tab label="Rendelések" value="orders" />
        <Tab label="Címek" value="addresses" />
      </Tabs>

      {/* Tab Tartalom */}
      {activeTab === "orders" && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Rendelések
          </Typography>
          {orders.length > 0 ? (
            <ul>
              {orders.map((order) => (
                <li key={order.id}>{order.name}</li>
              ))}
            </ul>
          ) : (
            <Typography>Nincsenek rendeléseid.</Typography>
          )}
        </Box>
      )}

      {activeTab === "addresses" && (
        <UserProfileForm
          cartItems={[]}
          onSubmit={() => {
            /* Callback funkció kezelése szükség esetén */
          }}
        />
      )}
    </Container>
  );
};

export default UserProfile;
