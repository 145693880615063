import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import ProductCard from "./ProductCard.tsx"; // Assuming ProductCard is imported here

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu"; // Backend URL

interface Product {
  _id: string;
  name: string;
  images: string[];
  price: number;
  stock: number;
}

const ProductSlider: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // API lekérése
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/products`);
        // Rendezd a termékeket készlet szerint csökkenő sorrendben
        const sortedProducts = response.data.sort(
          (a: Product, b: Product) => b.stock - a.stock
        );
        setProducts(sortedProducts.slice(0, 6)); // Csak az első 6 terméket jelenítjük meg
      } catch (error) {
        console.error("Hiba a termékek lekérésekor:", error);
      }
    };

    fetchProducts();
  }, []);

  // Az index folyamatosan változik a termékek csúsztatásához
  useEffect(() => {
    if (products.length > 4) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
      }, 3000); // 3 másodpercenként görgetés

      return () => clearInterval(interval); // Interval törlése, amikor a komponens unmountolódik
    }
  }, [products.length]);

  // Maximum 4 termék egyszerre látszódjon
  const visibleProducts =
    products.length > 4
      ? products.slice(currentIndex, currentIndex + 4)
      : products; // Ha kevesebb, mint 4 termék van, az összeset mutasd

  return (
    <Box sx={{ position: "relative", overflow: "hidden", width: "100%" }}>
      <Grid container spacing={2} sx={{ justifyContent: "left" }}>
        {visibleProducts.map((product) => (
          <Grid item key={product._id} xs={6} sm={6} md={3}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductSlider;
