import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  TextField,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Instagram } from "@mui/icons-material";
import { FaTiktok } from "react-icons/fa";
import CompanyLogoWhite from "../assets/habka_feher_logo.png";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    if (email) {
      alert(`Feliratkozva a hírlevélre: ${email}`);
      setEmail("");
    } else {
      setErrorMessage("Kérlek, add meg az email címedet!");
    }
  };

  return (
    <Box sx={{ backgroundColor: "primary.main", color: "white", py: 2 }}>
      {/* Felső rész: Hírlevél és Social ikonok */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: isMobile ? 2 : 4,
          margin: "0 auto",
          px: isMobile ? 2 : 3,
        }}
      >
        {/* Hírlevél */}
        <Box sx={{ textAlign: isMobile ? "center" : "left" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 300,
              color: "rgba(255, 255, 255, 0.8)",
              mb: 1,
            }}
          >
            Iratkozz fel a hírlevélünkre
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              placeholder="Email cím"
              size="small"
              value={email}
              onChange={handleEmailChange}
              sx={{
                width: isMobile ? "100%" : "250px",
                input: { color: "white" },
                label: { color: "white" },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" },
                },
              }}
            />
            <Button
              variant="outlined"
              onClick={handleSubscribe}
              sx={{
                borderColor: "white",
                color: "white",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                width: isMobile ? "100%" : "auto",
              }}
            >
              Feliratkozás
            </Button>
          </Box>
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errorMessage}
            </Typography>
          )}
        </Box>

        {/* Social ikonok */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: isMobile ? "center" : "flex-start",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.instagram.com"
            target="_blank"
          >
            <Instagram fontSize={isMobile ? "medium" : "large"} />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.tiktok.com"
            target="_blank"
          >
            <FaTiktok size={isMobile ? 24 : 30} />
          </IconButton>
        </Box>
      </Box>

      {/* Alsó rész: ÁSZF, Adatvédelmi Szabályzat és Copyright */}
      <Box
        sx={{
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          mt: 3,
          pt: 2,
          textAlign: "center",
          px: 3,
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Copyright bal oldalon */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              textAlign: { xs: "center", sm: "left" },
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <img
              src={CompanyLogoWhite}
              alt="Habka - Kézműves szappanok"
              style={{ height: isMobile ? "16px" : "20px" }}
            />
            <Typography variant="body2" sx={{ fontWeight: 300 }}>
              &copy; 2025, Készítette:{" "}
              <Link
                href="https://devoppy.com"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                underline="hover"
              >
                Devoppy
              </Link>
            </Typography>
          </Grid>

          {/* ÁSZF és Adatvédelmi Szabályzat jobb oldalon */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              textAlign: { xs: "center", sm: "right" },
              mt: isMobile ? 0 : 0,
            }}
          >
            <Link
              fontSize={14}
              href="/terms-and-conditions"
              color="inherit"
              underline="hover"
              sx={{ fontWeight: 200, mx: 2 }}
            >
              ÁSZF
            </Link>
            <Link
              fontSize={14}
              href="/privacy-policy"
              color="inherit"
              underline="hover"
              sx={{ fontWeight: 200 }}
            >
              Adatvédelmi Szabályzat
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
