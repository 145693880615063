import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const backendURL = process.env.REACT_APP_BACKEND_URL || "https://habka.hu";

const UserProfileForm: React.FC = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    billingAddress: "",
    billingCity: "",
    billingZipCode: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZipCode: "",
    companyName: "",
    companyTaxNumber: "",
  });

  const [isDifferentShipping, setIsDifferentShipping] = useState(false);
  const [isCompanyInvoice, setIsCompanyInvoice] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token found");

        const decodedToken: any = jwtDecode(token);
        const userId = decodedToken.id;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(`${backendURL}/api/users/${userId}`, {
          headers,
        });

        const userData = response.data;
        setUserDetails({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          email: userData.email || "",
          phoneNumber: userData.phoneNumber || "",
          billingAddress: userData.billingAddress || "",
          billingCity: userData.billingCity || "",
          billingZipCode: userData.billingZipCode || "",
          shippingAddress: userData.shippingAddress || "",
          shippingCity: userData.shippingCity || "",
          shippingZipCode: userData.shippingZipCode || "",
          companyName: userData.companyName || "",
          companyTaxNumber: userData.companyTaxNumber || "",
        });

        setIsDifferentShipping(!!userData.shippingAddress);
        setIsCompanyInvoice(!!userData.companyName);
      } catch (error) {
        console.error("Error fetching user details:", error);
        if (error.response) {
          console.error("Server Response:", error.response.data);
        }
      }
    };

    fetchUserDetails();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const decodedToken: any = jwtDecode(token);
      const userId = decodedToken.id;

      const headers = { Authorization: `Bearer ${token}` };

      // Struktúra összeállítása a backend által várt formában
      const payload = {
        billingDetails: {
          billingAddress: userDetails.billingAddress,
          billingCity: userDetails.billingCity,
          billingZipCode: userDetails.billingZipCode,
        },
        shippingDetails: isDifferentShipping
          ? {
              shippingAddress: userDetails.shippingAddress,
              shippingCity: userDetails.shippingCity,
              shippingZipCode: userDetails.shippingZipCode,
            }
          : null,
        companyDetails: isCompanyInvoice
          ? {
              companyName: userDetails.companyName,
              companyTaxNumber: userDetails.companyTaxNumber,
            }
          : null,
        phoneNumber: userDetails.phoneNumber,
      };

      await axios.put(`${backendURL}/api/users/${userId}`, payload, {
        headers,
      });

      console.log("Details saved successfully");
    } catch (error) {
      console.error("Error saving details:", error);
      if (error.response) {
        console.error("Server Response:", error.response.data);
      }
    }
  };

  return (
    <Box pb={4}>
      <Typography variant="h6" gutterBottom>
        Felhasználói adatok
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Vezetéknév"
            name="firstName"
            value={userDetails.firstName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Keresztnév"
            name="lastName"
            value={userDetails.lastName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={userDetails.email}
            onChange={handleInputChange}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Telefonszám"
            name="phoneNumber"
            value={userDetails.phoneNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Számlázási cím"
            name="billingAddress"
            value={userDetails.billingAddress}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Számlázási város"
            name="billingCity"
            value={userDetails.billingCity}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Számlázási irányítószám"
            name="billingZipCode"
            value={userDetails.billingZipCode}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            checked={isDifferentShipping}
            onChange={(e) => setIsDifferentShipping(e.target.checked)}
          />
        }
        label="Más a szállítási cím"
      />

      {isDifferentShipping && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Szállítási cím
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Szállítási cím"
                name="shippingAddress"
                value={userDetails.shippingAddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Szállítási város"
                name="shippingCity"
                value={userDetails.shippingCity}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Szállítási irányítószám"
                name="shippingZipCode"
                value={userDetails.shippingZipCode}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={isCompanyInvoice}
            onChange={(e) => setIsCompanyInvoice(e.target.checked)}
          />
        }
        label="Számlázási adatok"
      />

      {isCompanyInvoice && (
        <Box>
          <Typography variant="h6" gutterBottom>
             Céges adatok
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Cégnév"
                name="companyName"
                value={userDetails.companyName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adószám"
                name="companyTaxNumber"
                value={userDetails.companyTaxNumber}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mt: 3 }}
        fullWidth
      >
        Mentés
      </Button>
    </Box>
  );
};

export default UserProfileForm;
