import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BlogCardProps {
  blog?: {
    id: string;
    title: string;
    summary: string;
    image?: string;
    date: string;
  };
}

const backendURL = "https://habka.hu"; // Az alap URL, ahol a backend fut
const defaultImage = "/uploads/habka_nincs_kep.png"; // Alapértelmezett kép

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  const navigate = useNavigate();

  if (!blog) {
    // Ha a `blog` undefined, nem rendereljük a kártyát
    return null;
  }

  const handleCardClick = () => {
    navigate(`/blog/${blog.id}`);
  };

  const imageUrl =
    blog.image && blog.image.startsWith("/uploads/")
      ? `${backendURL}${decodeURIComponent(blog.image)}`
      : `${backendURL}${defaultImage}`;

  return (
    <Card
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: 0,
        overflow: "hidden",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f9f9f9",
        },
      }}
      onClick={handleCardClick}
    >
      {/* Kép */}
      <Box
        sx={{
          position: "relative",
          aspectRatio: "3 / 2",
          width: "100%",
        }}
      >
        <CardMedia
          component="img"
          image={imageUrl} // Kép URL-je
          alt={blog.title}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        {/* Dátum badge */}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            backgroundColor: "secondary.main",
            color: "black",
            borderRadius: 1,
            px: 1,
            py: 0.5,
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
        >
          {new Date(blog.date).toLocaleDateString("hu-HU", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Box>
      </Box>

      {/* Tartalom */}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Typography
          variant="h6"
          color="text.primary"
          fontWeight={600}
          gutterBottom
        >
          {blog.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {blog.summary}
        </Typography>
      </CardContent>

      <Box sx={{ p: 2 }}>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/blog/${blog.id}`);
          }}
          sx={{
            textTransform: "none",
          }}
        >
          Tovább olvasom...
        </Button>
      </Box>
    </Card>
  );
};

export default BlogCard;
