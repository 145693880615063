import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext.tsx";

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, wasUser } = useAuth();

  if (isAuthenticated) {
    return <Outlet />;
  }

  if (wasUser) {
    //console.log("Previously user role detected, navigating to home...");
    return <Navigate to="/" />;
  }

  //console.log("Unauthenticated, navigating to login...");
  return <Navigate to="/login" />;
};


export default ProtectedRoute;
