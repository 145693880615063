import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | null;
  isAdmin: boolean;
  wasUser: boolean;
  login: (token: string, user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded: { exp: number } = jwtDecode(token);
        return decoded.exp * 1000 > Date.now();
      } catch {
        return false;
      }
    }
    return false;
  });

  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [wasUser, setWasUser] = useState<boolean>(false);

  const isAdmin = user?.role === "admin";

  const login = (token: string, user: User) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify({ ...user, _id: user._id }));
    setUser({ ...user, _id: user._id });
    setIsAuthenticated(true);
  };

  const logout = React.useCallback(() => {
    const currentUser = user;
    if (currentUser?.role === "user") {
      setWasUser(true);
    } else {
      setWasUser(false);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setIsAuthenticated(false);
  
    if (currentUser?.role === "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded: { exp: number } = jwtDecode(token);
        if (decoded.exp * 1000 <= Date.now()) {
          logout();
        }
      } catch {
        logout();
      }
    }
  }, [logout]); // A logout függvény bekerült a dependency array-be

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, isAdmin, wasUser, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
