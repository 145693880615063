import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Grid } from "@mui/material";

const API_BASE_URL = "https://habka.hu/api";

const CompanyDetails: React.FC = () => {
  const [companyData, setCompanyData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    boss: "",
    bossTitle: "",
    taxNumber: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // API hívás: cégadatok lekérése
  const getCompanyInfo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/company`);
      if (!response.ok) {
        throw new Error("Hiba a cégadatok lekérdezésekor.");
      }
      const data = await response.json();
      if (data && Object.keys(data).length > 0) {
        setCompanyData(data); // Ha vannak adatok, állítsuk be őket
      }
    } catch (err) {
      console.error("Nem sikerült betölteni a cégadatokat.");
      setError("Nem sikerült betölteni a cégadatokat.");
    } finally {
      setLoading(false);
    }
  };

  // API hívás: cégadatok frissítése
  const updateCompanyInfo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(companyData),
      });

      if (!response.ok) {
        throw new Error("Hiba történt a cégadatok mentése során.");
      }

      const updatedData = await response.json();
      setCompanyData(updatedData);
      alert("Cégadatok sikeresen mentve!");
    } catch (err) {
      setError("Nem sikerült menteni a cégadatokat.");
    }
  };

  // Adatok betöltése a komponens betöltésekor
  useEffect(() => {
    getCompanyInfo();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (loading) {
    return <Typography variant="h6">Adatok betöltése...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ maxWidth: "lg", mx: "auto", mt: 2, pb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Cégadatok
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Cégnév"
            name="name"
            value={companyData.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={companyData.email}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Telefonszám"
            name="phone"
            value={companyData.phone}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Cím"
            name="address"
            value={companyData.address}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Ügyvezető neve"
            name="boss"
            value={companyData.boss}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Ügyvezető beosztása"
            name="bossTitle"
            value={companyData.bossTitle}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Adószám"
            name="taxNumber"
            value={companyData.taxNumber}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={updateCompanyInfo}>
        Mentés
      </Button>
    </Box>
  );
};

export default CompanyDetails;
