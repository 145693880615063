import React from "react";
import { Container, Grid, Typography, Divider, Box, Button } from "@mui/material";
import { useCart } from "../context/CartContext.tsx";
import CheckoutForm from "../components/CheckoutForm.tsx";

const CheckoutPage = () => {
  const { cartItems } = useCart();

  const handleSubmit = (
    billingDetails: any,
    shippingDetails: any,
    companyDetails: any
  ) => {
    //console.log("Billing Details:", billingDetails);
    //console.log("Shipping Details:", shippingDetails);
    //console.log("Company Details:", companyDetails);
  };

  const totalWithoutDiscount = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Fizetés
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <CheckoutForm cartItems={cartItems} onSubmit={handleSubmit} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Összesítő
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box>
            {cartItems.map((item) => (
              <Box key={item._id} sx={{ mb: 2 }}>
                <Typography>
                  {item.name} - {item.price.toLocaleString()} Ft x{" "}
                  {item.quantity}
                </Typography>
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">
              Összesen kedvezmény nélkül:{" "}
              {totalWithoutDiscount.toLocaleString()} Ft
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ mt: 3 }}
          >
            Fizetés
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutPage;
