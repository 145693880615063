import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import CompanyLogo from "../assets/HABKA_logo.png";
import ProductSlider from "../components/ProductSlider.tsx";

const API_BASE_URL = "https://habka.hu/api";

function AboutUs() {
  const [companyData, setCompanyData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    boss: "",
    bossTitle: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // API hívás a cégadatok lekérésére
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/company`);
        if (!response.ok) {
          throw new Error("Hiba a cégadatok lekérdezésekor.");
        }
        const data = await response.json();
        setCompanyData(data);
      } catch (err) {
        setError("Nem sikerült betölteni a cégadatokat.");
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, []);

  const companyDetails = [
    { label: "Cégnév", value: companyData.name },
    {
      label: "Email cím",
      value: (
        <Link href={`mailto:${companyData.email}`}>{companyData.email}</Link>
      ),
    },
    { label: "Telefonszám", value: companyData.phone },
    { label: "Cím", value: companyData.address },
    { label: companyData.bossTitle, value: companyData.boss },
  ];

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">Adatok betöltése...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Rólunk szakasz */}
      <Grid container spacing={4} alignItems="center">
        {/* Bal oldal: Logo */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={CompanyLogo}
            alt="Habka Logo"
            sx={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: 2,
              display: "block",
              margin: "0 auto",
            }}
          />
        </Grid>

        {/* Jobb oldal: Rólunk szöveg */}
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom>
            Rólunk
          </Typography>
          <Typography variant="body1" paragraph>
            Üdvözlünk a cégünk oldalán! Szenvedélyesen dolgozunk azon, hogy a
            legmodernebb termékeket és a legjobb vásárlói élményt nyújtsuk
            neked. Nézd meg a kiemelt ajánlatainkat!
          </Typography>
        </Grid>
      </Grid>

      {/* Céges adatok */}
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Céges adatok
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {companyDetails.map((detail, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              elevation={3}
              sx={{ p: 2, textAlign: "center", width: "100%" }}
            >
              <CardContent>
                <Typography variant="subtitle1" fontWeight={600}>
                  {detail.label}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {detail.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Termékajánlások */}
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Termékajánlások
      </Typography>
      <ProductSlider />
    </Container>
  );
}

export default AboutUs;
