import React, { useState, useEffect } from "react";
import { Container, Button, Typography, Grid, Box } from "@mui/material";
import BlogCard from "./PostCard.tsx";

const API_BASE_URL = "https://habka.hu/api";

interface PostsProps {
  onEditPost: (postId: string) => void;
}

const Posts: React.FC<PostsProps> = ({ onEditPost }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/posts`);
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Hiba a postok lekérésekor:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await fetch(`${API_BASE_URL}/posts/${id}`, {
        method: "DELETE",
      });
      setPosts(posts.filter((post) => post._id !== id));
    } catch (error) {
      console.error("Hiba a post törlésekor:", error);
    }
  };

  const handleAddPost = () => {
    onEditPost(null); // Navigálás az új post hozzáadására
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  if (loading) {
    return <Typography variant="h6">Adatok betöltése...</Typography>;
  }

  return (
    <Container>
      {/* Új post hozzáadása gomb */}
      <Box sx={{ mb: 3, textAlign: "right" }}>
        <Button variant="contained" color="primary" onClick={handleAddPost}>
          Új Post Hozzáadása
        </Button>
      </Box>

      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post._id}>
            <Box sx={{ position: "relative" }}>
              <BlogCard blog={post} />
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  gap: 1,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => onEditPost(post._id)}
                >
                  Szerkesztés
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(post._id)}
                >
                  Törlés
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Posts;
