import React from "react";
import { Box, Typography, Container } from "@mui/material";
import ProductSlider from "../components/ProductSlider.tsx"; // Importáljuk a ProductSlider komponenst
import Hero from "../components/Hero.tsx"; // Importáljuk a Hero komponenst

export default function Home() {

  return (
    <Box>
      <Hero /> {/* Hero komponens használata */}
      <Container maxWidth="lg" sx={{ mt: 6 }}>
        <Typography variant="h4" gutterBottom>
          Népszerű Termékek
        </Typography>
        <ProductSlider />
      </Container>
    </Box>
  );
}
