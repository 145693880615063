import { createTheme } from "@mui/material/styles";
import "@fontsource/urbanist"; // Normál súly
import "@fontsource/urbanist/200.css"; // Normál
import "@fontsource/urbanist/400.css"; // Normál
import "@fontsource/urbanist/500.css"; // Félkövér
import "@fontsource/urbanist/700.css"; // Kövér

const theme = createTheme({
  palette: {
    primary: {
      main: "#3C3633",
      light: "#93C5FD",
      dark: "#1E3A8A",
    },
    secondary: {
      main: "#F7FAFC",
      light: "#4D4D4D",
      dark: "#0D0D0D",
    },
    background: {
      default: "#F7FAFC",
      paper: "#F7FAFC",
    },
    text: {
      primary: "#2D3748",
      secondary: "#A0AEC0",
    },
    success: {
      main: "#22C55E",
      light: "#86EFAC",
      dark: "#15803D",
    },
    error: {
      main: "#F87171",
      light: "#FCA5A5",
      dark: "#DC2626",
    },
  },
  typography: {
    fontFamily: "Urbanist, Arial, sans-serif",
    h1: {
      fontSize: "10rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    h2: {
      fontSize: "6rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    h3: {
      fontSize: "4rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    h4: {
      fontSize: "3rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    h5: {
      fontSize: "2rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 200,
      lineHeight: 1,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "primaryButton" },
          style: {
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#004ba0",
            },
          },
        },
        {
          props: { variant: "secondaryButton" },
          style: {
            backgroundColor: "#dc004e",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#9a0036",
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: "18px",
        },
        body: {
          margin: 0,
          padding: 0,
          fontFamily: "Urbanist, Arial, sans-serif",
        },
      },
    },
  },
});

export default theme;
